@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

:root {
  --p: 50.77% 0.202 23.9;
  --pc: 96.05% 0.013 56.25;
  --n: 19.62% 0.064 257.65;
  --nc: 83.98% 0.013 255.52;
}

h1 {
  @apply text-5xl;
}
h2 {
  @apply text-4xl;
}
h3 {
  @apply text-3xl;
}
h4 {
  @apply text-2xl;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
}

.dot {
  border-radius: 9999px;
  animation: pulse 2.2s infinite alternate;
  box-shadow: 0 0 8px;
}
.dot:first-child {
  animation-delay: 0s;
}
.dot:last-child {
  animation-delay: 0.75s;
}

.checkout .brand-text, .checkout .brand-slogan {
  @apply absolute text-[oklch(var(--pc))];
}
.checkout .brand-text {
  @apply text-lg sm:text-2xl font-normal mr-0 mb-0 ml-12;
  margin-top: -2.8rem;
  letter-spacing: 2px;
}
.checkout .brand-slogan {
  @apply text-sm inline-block font-bold font-['Caveat'] text-nowrap -mt-4 ml-12;
  letter-spacing: 3.2px;
  
}

#consent-manager-update-banner.optimized-checkout-cmu {
  @apply bg-[oklch(var(--n)/90%)] fixed bottom-0 left-0 right-0 z-50 text-base font-normal leading-10 py-2;
}
#consent-manager-update-banner.optimized-checkout-cmu a {
  color: oklch(var(--nc)) !important;
}

.button {
  padding: 11px 20px;
  line-height: 20px;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 15px;
  border-radius: 0px;
  letter-spacing: 0.10px;
  height: auto;
  border: 2px solid oklch(var(--p));
  color: oklch(var(--pc));
  background: oklch(var(--p));
}
@media only screen and (max-width: 767px) {
  .button {
    padding: 8px 18px;
    font-size: 14px;
  }
}
.button:hover, .button:focus, .button:active {
  background: oklch(var(--pc));
  border: 2px solid oklch(var(--p));
  color: oklch(var(--p));
}
.button.button--primary {
  background: oklch(var(--p));
  border: 2px solid oklch(var(--p));
  color: oklch(var(--pc));
  
}
.button.button--primary:hover, .button.button--primary:focus, .button.button--primary:active{
  color: oklch(var(--p));
  background: oklch(var(--pc));
  border: 2px solid oklch(var(--p));
}
